.box-app-version {
  font-size: 10px;
  position: fixed;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  padding: 3px;
  z-index: 9999;
}

.g-custom-navbar .g-user-menu .actionable .fullName {
  color: #fff;
}

.g-custom-navbar .g-user-menu .toggleable-menu .links-list li {
  text-align: left;
  justify-content: start;
}